import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageLayout from "./components/PageLayout";
import AboutPage from "./pages/AboutPage/AboutPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import WorkDetailPage from "./pages/WorkDetailPage/WorkDetailPage";
import WorkPage from "./pages/WorkPage/WorkPage";
import "./styles/App.scss"
import ReactGA from 'react-ga';


ReactGA.initialize('UA-223743104-1');




function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location])

  return (
    <div>
      <PageLayout>
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/workDetail/:id" element={<WorkDetailPage />} />
          <Route path="/work" element={<WorkPage />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </PageLayout>
    </div>
  );
}

export default App;
