import React from 'react';

function Pills({ list, type, theme }) {

    if (!theme) {
        theme = {
            bg: "#2BFD86",
            color: "black",
            cardBg: 'white'
        }
    }
    if (!list.length) return <div />

    const renderItem = () => {
        return list.map(item => {
            return (
                <div className='item' key={item} style={{ backgroundColor: theme.color, color: theme.bg }}> {item} </div>
            )
        })
    }

    return (
        <div className='pills'>
            {renderItem()}
        </div>
    )
}

export default Pills;