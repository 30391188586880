import React from 'react';
import ReactGA from 'react-ga';

function Footer(props) {

    const handleClick = () => {
        ReactGA.event({
            category: 'Navigation',
            action: `clicked`,
            value: "Linkedin CTA"
          });
    }

    return (
        <div className='contact-wrapper'>
            <div>
                <span style={{ marginRight: 10 }}><i className="fab fa-linkedin icon"></i></span>
                <a className='link-tag' href="https://www.linkedin.com/in/samir-jha-b5a62b1a/" onClick={handleClick}> <u>LinkedIn</u> </a>
            </div>
            <div className='link-tag'>
                <span style={{ marginRight: 10 }}>
                    <i className="fas fa-phone-alt"></i>
                </span>
                (240)782-8301
            </div>
            <div className='link-tag'>
                <span style={{ marginRight: 10 }}>
                    <i className="fas fa-envelope"></i>
                </span>
                samirjha642@gmail.com
            </div>
        </div>
    );
}

export default Footer;