import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ColoredSection from '../../../../components/ColoredSection';
import ImageSection from '../../../../components/ImageSection';
import Ratings from '../../../../components/Ratings';
import SafeContainer from '../../../../components/SafeContainer';
import { bgColorCombo } from '../../../../styles/bgColorCombo';
import VisibilitySensor from 'react-visibility-sensor';
import LeanCanvasImage from "./figure0.jpg";
import HypothesisImage from "./figure1.jpg"
import MOderatedImage from "./figure2.jpg"
import AnalysisImage from "./figure3.jpg"
import LofiImage from "./figure4.jpg"
import HifiImage from "./figure5.jpg"
import ReactAppImage from "./figure6.jpg"
import FinalAppImage from "./figure7.jpg"
import Pills from '../../../../components/Pills';

function DebtSimDesign() {

    const [bgTheme, setBgTheme] = useState(bgColorCombo[1])
    const toolsUsed = ["Adobe XD", "MIRO Board", "ReactJS", "AWS S3 Storage Hosting", "Lean Canvas"];
    const debtResult = ["Debt is stressful", "I cannot picture all my debt in one place", "I do know when it is ending"];
    // const isMobile = useMediaQuery({ query: '(max-width: 720px)' })
    return (
        <div className='fade-in' style={{ backgroundColor: bgTheme.bg, color: bgTheme.color, transition: 'all 1s ease-in' }}>
            <SafeContainer>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(event ? bgColorCombo[1] : bgColorCombo[2])
                    }}
                >
                    <div className='title'>
                        Debt Simulator – MoneyCast
                    </div>
                </VisibilitySensor>
                <ImageSection isTop={true} url={FinalAppImage} caption={"Final Debt Simulator Application - MoneyCast"} />
                <div className='heading'>
                    Tools Used
                </div>
                <ColoredSection isReveal bg={bgTheme.cardBg}>
                    <Pills list={toolsUsed} type={"inLine"} theme={bgTheme} />
                </ColoredSection>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[2])
                    }}
                >
                    <div className='heading'>
                        Background
                    </div>
                </VisibilitySensor>

                <div className='line'>
                    My team's original concept or hint was a plain statement from the user interview findings stating
                    – "Our users are uncomfortable with debt or handle debt."
                </div>
                <div className='line'>
                    I work in the Digital Acceleration Team,
                    which consists of all the skill sets we would need
                    to work on any design project. We have a Project manager,
                    a Business Analyst, an IT Architect, and myself – UX Developer.
                    When we were assigned to find a solution for this problem,
                    we had no clue what a solution would look like and where we should start.
                </div>
                <div className='heading'>
                    Initial Phase
                </div>
                <div className='line'>
                    To start with a single-line statement, we had to adapt
                    the lean canvas framework to find what we were dealing
                    with and our hypotheses. So, our team began brainstorming
                    ideas on Lean canvas with existing data from the
                    CX team and individual contributions.
                </div>
                <div className='line'>
                    We came up with an initial lean canvas with many iterations and brainstorming.
                </div>
                <ImageSection url={LeanCanvasImage} caption={"Initial Lean Canvas for Debt Simulator"} />
                <div className='line'>
                    To start with, we partnered with the CX team to survey Arvest customers on what they think about debt and if they are doing anything to resolve or tackle their debt. After filling up all canvas pieces, some noticeable results popped right away. A surprising 53% said they are not doing anything to work on their debt. Moreover, everyone described debit as stressful and had no picture of debt in the future. This emotion to the debt carries out through the experiment.
                    Our team immediately concluded that we might need to build or use an existing service that would give a customer a picture of their debt and suggest ways to tackle their debt.
                </div>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[3])
                    }}
                >
                    <div className='heading'>
                        Getting deeper in our hypotheses
                    </div>
                </VisibilitySensor>

                <div className='line'>
                    After knowing where we could start exploring the solution, we decided to do one more round of user interviews using UserTesting.com.
                    We wanted a "moderated interview" and kind of talk to the user personally about debt and hardship they face with it.
                    But as being a financial institute, we had the hurdle of compliance.
                </div>
                <div className='line'>
                    We had to move fast, so I came up with an idea to create a react application that would go through a question flow we wanted
                    to ask the user. Although it would not be as effective as an actual moderated interview, it was still good enough
                    for us to get some directed answers from the users. We came up with a question flow that would work for us, and I developed a react application.
                </div>
                <div className='line'>
                    I successfully hosted the application on usertesting.com, and we started the interview.
                    This time we did not limit subjects to Arvest customers. It was accessible to any demographics inside the USA.
                </div>
                <ImageSection url={HypothesisImage} caption={"Brainstorming UserTesting Questionnaire Flow"} />
                <ImageSection url={MOderatedImage} caption={"React Questionnaire Application in Usertesting.com"} />
                <div className='line'>
                    After finishing ten unmoderated interviews,
                    we had our answers to many questions. We were able to analyze all the answers and
                    study what the solution could be. Throughout the interview, many subjects had a widespread concern:
                </div>
                <br />
                <ColoredSection isReveal bg={bgTheme.cardBg}>
                    <Pills list={debtResult} type={null} theme={bgTheme} />
                </ColoredSection>
                <ImageSection url={AnalysisImage} caption="Interview Answers Analysis" />
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[4])
                    }}
                >
                    <div className='heading'>
                        Design Sprint
                    </div>
                </VisibilitySensor>

                <div className='line'>
                    After narrowing down insight from the unmoderated interview in UserTesting.com,
                    our team sat down for five days design sprint. We also invited a few more key stakeholders
                    who would play an essential role in contributing to our effort.
                </div>
                <div className='line'>
                    We came up with a web app that would collect all the debt and show
                    the future forecast on when they would finish paying by using a different strategy.
                    Now it was time for me to shine and show my magic.
                </div>
                <div className='heading'>
                    Lo-fi Prototype
                </div>
                <div className='line'>
                    Firstly, I collected everyone's design,
                    the solution, and the final design we all agreed to
                    implement and designed 5 mins lo-fi design. It was effortless with just squares and text.
                </div>
                <ImageSection url={LofiImage} caption={"Lo-fi Debt Simulator Design"} />
                <div className='heading'>
                    Hi-fi Prototype
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ flexBasis: "50%" }}>
                        <ImageSection url={HifiImage} isHalf={true} caption={"Hi-fi Prototype Design"} />
                    </div>
                    <div style={{ flexBasis: "50%" }}>
                        <div className='line'>
                            After getting more feedback and iteration, I started working on a hi-fi prototype
                            in Adobe XD to test in front of the user through the UserTesting platform.
                        </div>
                        <div className='line'>
                            When we put the prototype in infront of the user, we got honest comments that
                            the prototype was not so cool and did not have animation. We knew we would get that
                            feedback as the prototype was very basic, and we were only concerned about the application flow.
                        </div>
                        <div className='line'>
                            The outcomes were beneficial; I could see the issues with
                            application flow and the user's pain points while using the prototype. I also collected nice to have features from the users.
                        </div>
                    </div>
                </div>
                <div className='heading'>
                    React Application Development
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ flexBasis: "50%" }}>
                        <ImageSection url={ReactAppImage} isHalf={true} caption={"React application with Arvest Design System"} />
                    </div>
                    <div style={{ flexBasis: "50%" }}>
                        <div className='line'>
                            Finally, after getting users' feedback through a hi-fi prototype,
                            I had a good idea of what to build in react. It took me three days to make the application,
                            and it was ready to go back to another round of UserTesting to get the feedback.
                        </div>
                        <div className='line'>
                            We started getting positive feedback on how proper this application can be for a user to forecast and get a graphical picture of their debt.
                        </div>
                    </div>
                </div>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[1])
                    }}
                >
                    <div className='heading'>
                        Finally, and what is next
                    </div>
                </VisibilitySensor>

                <div className='line'>
                    We concluded the UserTesting experiment, and we are now working
                    on integrating the application into our existing digital platform.
                    We also implemented Google Analytics tags to get metrics on how the application behaves and get insights.
                </div>
                <Ratings />
            </SafeContainer>

        </div>
    );
}

export default DebtSimDesign;