import React from 'react';
import { ReactComponent as Shapes } from "./shapes.svg"
import { ReactComponent as Brand } from "./brand.svg"
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "./LandingPage.scss"
import ReactGA from 'react-ga';

function LandingPage() {
const navigate = useNavigate();
const isMobile = useMediaQuery({ query: '(max-width: 720px)' }) 

    const handleNavigation = () => {
        navigate("/work");
        ReactGA.event({
            category: 'Navigation',
            action: 'LandingPage - WORK - CTA CLICKED',
            value: 'Work CTA'
          });
    }


    if (!isMobile) {
        return (
            <div className='LandingPage fade-in'>
                <div className='center-wrapper'>
                    <div className='text'>
                        <div className='hi drop-down'>Hi!</div>
                        <div className='longText'>
                        I'm Samir Jha, a UX Designer and Frontend Developer specializing in rapid prototyping and lean startup experimentation.
                        </div>
                        <Brand className="brand" />
                        {/* <div className='work' onClick={handleNavigation}>
                            WORK <i className="fas fa-arrow-right arrowIcon"></i>
                        </div> */}
                    </div>
                    <Shapes className='shapes fade-in' />
                </div>
    
            </div>
        );
    }

    return (
        <div className='LandingPage-mobile fade-in'>
                <div className='shapes' >
                <Shapes />
                </div>

                    <div className='text'>
                        <div className='hi drop-down'>Hi!</div>
                        <div className='longText'>
                        I'm Samir Jha, a UX Designer and Frontend Developer specializing in rapid prototyping and lean startup experimentation.
                        </div>
                        <Brand className="brand" />
                        {/* <div className='work' onClick={handleNavigation}>
                            WORK <i className="fas fa-arrow-right arrowIcon"></i>
                        </div> */}
                    </div>
            </div>
    )

 
}

export default LandingPage;