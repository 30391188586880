import React, { useEffect, useState } from 'react';
import { useNavigate , useLocation} from "react-router-dom";
import ReactGA from 'react-ga';


function NavBar({ }) {
    const [page, setPage] = useState('intro')
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let url = location.pathname.split("/")
        if(url.length > 2) return;
        if (!url[1]) {
            setPage("intro")
        } else {
            setPage(url[1])
        }
      }, [location])

    const handleNavigation = (url) => {
        navigate(`/${url}`);
        ReactGA.event({
            category: 'Navigation',
            action: `WEB CTA ${url}`
          });
    }



    return (
        <div className='NavBar'>
            <div className='flex-wrapper'>
                <div className='name'>
                    SAMIR JHA <span className='position' > UX/UI Design Developer </span>
                </div>
                <div className='menu'>
                    <div className={page === "intro" ? 'menuItem active' : 'menuItem'} onClick={() => handleNavigation('')}> INTRO  </div>
                    {/* <div className={page === "work" ? 'menuItem active' : 'menuItem'} onClick={() => handleNavigation('work')}> WORK </div> */}
                    <div className={page === "about" ? 'menuItem active' : 'menuItem'} onClick={() => handleNavigation('about')}> ABOUT </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;