import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';




function NavBarMobile() {
    const [page, setPage] = useState('intro')

    const navigate = useNavigate();


    const handleNavigation = (url) => {
        if (!url) {
            setPage("intro")
        } else {
            setPage(url)
        }
        navigate(`/${url}`);
        ReactGA.event({
            category: 'Navigation',
            action: `WEB CTA ${url}`
        });
    }


    return (
        <div className='NavBarMobile'>
            <div className='flex-wrapper'>
                <div className='menu'>
                    <div className={page === "intro" ? 'menuItem active' : 'menuItem'} onClick={() => handleNavigation('')}> INTRO  </div>
                    {/* <div className={page === "work" ? 'menuItem active' : 'menuItem'} onClick={() => handleNavigation('work')}> WORK </div> */}
                    <div className={page === "about" ? 'menuItem active' : 'menuItem'} onClick={() => handleNavigation('about')}> ABOUT </div>
                </div>
            </div>
        </div>
    );
}

export default NavBarMobile;