import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Footer from './Footer';
import NavBar from './NavBar';
import NavBarMobile from './NavBarMobile';

function PageLayout({children}) {
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' })

    return (
        <>
        {isMobile ? (
            <NavBarMobile />
        ):(
            <NavBar />
        )}
        {children}
        <Footer />
        </>
    );
}

export default PageLayout;