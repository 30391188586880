import React, { useEffect, useState } from 'react';
import WorkCard from '../../components/WorkCard';
import { workData } from '../../workData';
import ReactGA from 'react-ga';


function WorkPageMobile(props) {
    const [workItem, setWorkItem] = useState([]);
    const [navState, setNavState] = useState('design');

    useEffect(() => {
        const filerWorkItem = workData.filter(item => item.type === navState);
        setWorkItem([...filerWorkItem]);
    }, []);

    const handleSideNav = (nav) => {
        const filerWorkItem = workData.filter(item => item.type === nav);
        setWorkItem([...filerWorkItem]);
        setNavState(nav)
        ReactGA.event({
            category: 'Tab',
            action:  'clicked',
            value: "mobile  " + nav
          });
    }


    return (
        <div className='WorkPageMobile fade-in'>
            <div className='splitMenu'>
                <div
                    className={navState === "design" ? 'navItem activeTab' : 'navItem'}
                    onClick={() => handleSideNav('design')}>
                    DESIGN </div>
                <div
                    className={navState === "code" ? 'navItem activeTab' : 'navItem'}
                    onClick={() => handleSideNav('code')}>
                    CODE  </div>
            </div>
            <div className='cards-wrapper'>
            {workItem.map(item => {
                        return <div className='card-wrapper' key={item.id}>
                            <WorkCard image={item.thumbnail} tags={item.tags} header={item.title} id={item.id} body={item.summary} />
                        </div>
                    })}
            </div>
        </div>
    );
}

export default WorkPageMobile;