export const workData = [
    {
        type: "design",
        id: "design-debtsim",
        title: "Debt Simulator Design",
        thumbnail: 'debtSim.png',
        summary: "Designed a prototype of a debt simulator application to manage customers debt",
        tags: ["Design Thinking", "Wireframe", "ReactJS"]
    },
    {
        type: "design",
        id: "design-arvestgo",
        title: "Arvest Go Prototype",
        thumbnail: 'arvestGo.png',
        summary: "Re-designed the complete working prototype of the Arvest online banking application",
        tags: ["Wireframe"],
        details: {
            title: "Arvest Go Prototype",
            wallpaper:"arvestgo.png",
            background: `The Arvest Go app is a mobile banking application for personal account holders in the bank. There are more than 800,000 digital users in Arvest and many web applications for different lines of business. Among those applications, Arvest Go has the highest user traffic. 
            As the Arvest Go app has enormous traffic, most personal banking experience piloting and new feature tests are performed in this application.`,
            problem:`In my first few weeks of employment at the Arvest bank,  I realized none of the bank departments had a working prototype of Arvest Go. This slowed down new feature designs and third-party application integration experience. 
            As the application development team was a vendor, the vendor would charge big money to prototype an idea.`,
            solution:`I created the exact working prototype Arvest Go application in Adobe XD with my supervisor's support. With the sharable link to the prototype, many departments can leverage the power of Adobe XD and work with their team to ideate and solve feature issues in the application. It provides a clear picture for any user who is using the application. Moreover, it saves contractor hours payment by having the prototype built in-house.`

        }
    },
    // {
    //     type: "design",
    //     id: "design-blueiq",
    //     title: "Arvest Blue IQ",
    //     thumbnail: 'blueiq.png',
    //     summary: "Re-designed the complete working prototype of the Arvest consumer application",
    //     details: {
    //         title: "Arvest Blue IQ",
    //         wallpaper:"blueIQ.PNG",
    //         background: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing`,
    //         problem: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubt`,
    //         solution:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`

    //     }
    // },
  
    // {
    //     type: "design",
    //     id: "design-bingo",
    //     title: "Bingo Mingo Prototype",
    //     thumbnail: 'bingo.png',
    //     summary: "Designed a prototype of a fun bingo game to play with the office colleagues ",
    //     details: {
    //         title: "Bingo Mingo Prototype",
    //         wallpaper:"BingoMingo.PNG",
    //         background: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing`,
    //         problem: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubt`,
    //         solution:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`

    //     }
    // },
    {
        type: "code",
        id: "code-bingo",
        title: "BingoMingo Game App",
        thumbnail: 'bingo.png',
        summary: "Built a fun bingo game to play with the office colleagues and family members",
        tags: [ "Wireframe", "ReactJS", "NodeJS", "SocketIO"],
        details: {
            title: "Bingo Mingo App",
            wallpaper:"BingoMingo.PNG",
            background: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing`,
            problem: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubt`,
            solution:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`

        }
    },
    // {
    //     type: "code",
    //     id: "code-debtSim",
    //     title: "Debt Simulator App",
    //     thumbnail: 'debtSim.png',
    //     summary: "Built a debt simulator application to manage customers debt",
    //     details: {
    //         title: "Debt Simulator App",
    //         wallpaper:"debtSimCode.png",
    //         background: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing`,
    //         problem: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubt`,
    //         solution:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`

    //     }
    // }
]