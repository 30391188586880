import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
let done = false;

function ImageSection({ url, caption, isHalf, isTop }) {
    let classChange = 'ImageSection reveal'
    if(isTop) {
        classChange = 'ImageSection drop-down'
    }
    return (
        <div className={classChange}
            style={{
                animationDelay: '.3s',
                position: 'relative'
            }}
        >
            <div>
                <img className='image' style={isHalf ? { maxWidth: 600 } : { maxWidth: 1200 }} src={url} />
            </div>
            <div className='caption'>
                <i> {caption}</i>
            </div>
        </div>
    );
}

export default ImageSection;