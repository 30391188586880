import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import "./WorkDetailPage.scss";
import DebtSimDesign from './project-detail-pages/debt-sim-design/DebtSimDesign';
import ArvestGoProto from './project-detail-pages/arvest-go-proto/ArvestGoProto';
import BingoMingoCode from './project-detail-pages/bingoMingo-code/BingoMingoCode';

function WorkDetailPage() {
    const { id } = useParams();
    useEffect(() => {
        window.scrollTo(0,0);
        window.addEventListener('scroll', scrollEvent);
        return () =>  window.removeEventListener('scroll', scrollEvent);
    },[])

    const scrollEvent = (e) => {
        const reveals = document.querySelectorAll('.reveal');
        if(!reveals.length) return;
        for(let i = 0; i < reveals.length; i++) {
            let windowHeight = window.innerHeight;
            let revealTop = reveals[i].getBoundingClientRect().top;
            let revealPoint = 200;
            if(revealTop < windowHeight - revealPoint) {
                reveals[i].classList.add('active');
            } else {
                reveals[i].classList.remove('active');
            }

        }

    }

    switch (id) {
        case "design-debtsim":
            return <DebtSimDesign />
        case "design-arvestgo":
            return <ArvestGoProto />
        case "code-bingo":
            return <BingoMingoCode />
        default:
            return <div> No Page </div>
    }
}

export default WorkDetailPage;