import React, { useState } from 'react';
import ColoredSection from '../../../../components/ColoredSection';
import ImageSection from '../../../../components/ImageSection';
import Ratings from '../../../../components/Ratings';
import SafeContainer from '../../../../components/SafeContainer';
import AdobeXD from "./figure1.jpg";
import GameFlow from "./figure2.jpg";
import FEcode from "./figure3.jpg";
import BEcode from "./figure5.jpg";
import Sana from "./figure6.jpg";
import SoloGame from "./figure7.JPG";
import BingoMingo from "./BingoMingo.png";
import { bgColorCombo } from '../../../../styles/bgColorCombo';
import VisibilitySensor from 'react-visibility-sensor';
import Pills from '../../../../components/Pills';

function BingoMingoCode() {
    const [bgTheme, setBgTheme] = useState(bgColorCombo[1])
    const toolsUsed = ['Adobe XD', 'ReactJS', 'NODEJS', 'AWS S3 Storage Hosting', 'SOCKET IO'];
    const interfaces = ['Spin Dashboard', 'Players Number Board']
    return (
        <div className='fade-in' style={{ backgroundColor: bgTheme.bg, color: bgTheme.color, transition: 'all 1s ease-in' }}>
            <SafeContainer>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(event ? bgColorCombo[1] : bgColorCombo[2])
                    }}
                >
                    <div className='title'>
                        BingoMingo Game App
                    </div>
                </VisibilitySensor>

                <div className='line'>
                    BingoMingo is a family bingo game that the individual can play by creating a private game room.
                </div>

                <div className='heading'>
                    Tools used
                </div>
                <ColoredSection bg={bgTheme.cardBg}>
                    <Pills list={toolsUsed} type={"inLine"} theme={bgTheme} />
                </ColoredSection>
          
                <div className='heading'>
                        Background
                    </div>

                <div className='line'>
                    In the 2021 holidays, I attended a family gathering party at my friend's house. They had planned for a bingo game that night, and the preparation was excellent. They had cut all the paper numbers required and spent a reasonable amount of time preparing for the game. We had fun, but the host had difficulty managing numbers and players in the game.
                    I thought this was a terrific concept to experiment with throughout the game
                    and can be a great online game that resolves all the hardship the host was
                    going through to manage the game manually.
                </div>
                <div className='line'>
                    I created the initial game flow in Adobe Xd.
                    I wanted to keep the flow as simple as possible and <strong>create an MVP
                        to validate my concept</strong>. It took me a week to start an XD concept to creating actual servers and webpage applications, start to finish!
                </div>
                <ImageSection url={AdobeXD} caption={"Initial concept and screen flow design"} />
                <div className='line'>
                    The whole gaming experience has two interfaces.
                </div>
                <br />
                <ColoredSection isReveal bg={bgTheme.cardBg}>
                    <Pills list={interfaces} type={"inLine"} theme={bgTheme} />
                </ColoredSection>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[2])
                    }}
                >
                <div className='heading'>
                    Spin Dashboard
                </div>
                </VisibilitySensor>
             
                <div className='line'>
                    The spin dashboard is the admin dashboard that can be cast on a big screen to draw numbers for the game and display previously drawn numbers. It also has the QR link to join the fun.
                </div>
                <div className='line'>
                    <i>Everyone highly admired the ease of QR code game joining!</i>
                </div>
                <div className='heading'>
                    Players Number Board
                </div>
                <div className='line'>
                    The number board is the interface where players play their game. It can be joined through any smart device and is mobile responsive.
                </div>
                <ImageSection url={GameFlow} caption={"Overview of the game flow"} />
                <br /> <br />
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[3])
                    }}
                >
                    <div className='heading'>
                        Tech Stack Overview
                    </div>
                </VisibilitySensor>

                <div className='line'>
                    The whole application has the backend and the frontend component. As an experiment, there is no database component. All the data are stored in a JSON file on the server.
                </div>
                <div className='heading'>
                    Frontend Stack
                </div>
                <div className='line'>
                    The front end uses the ReactJS framework. The game component, the dashboard,
                    and the player's board are created in the same application with different routes.
                    So no mess managing two separate applications, and we can utilize the same style component if needed.
                </div>
                <div className='line'>
                    The server communication is authenticated through a
                    temporary token and uses Axios to make an HTTP call to the server.
                    The application also utilizes SocketIO client for live event broadcasts, like if any player scores a bingo combination.
                </div>
                <ImageSection url={FEcode} caption={"React router source code screenshot"} />
                <div className='heading'>
                    Backend And Hosting Overview
                </div>
                <div className='line'>
                    The backend had a microservice architecture with ExpressJS service and Socket IO service components.
                </div>
                <div className='heading'>
                    ExpressJS Service
                </div>
                <div className='line'>
                    This server handles all the HTTP calls from the client to operate the game,
                    like creating the game, players getting the random number sequence, drawing random numbers, etc.
                    It also communicates with the SocketIO server internally to broadcast any critical events.
                </div>
                <ImageSection url={BEcode} caption={"ExpressJS router code screenshot"} />
                <div className='heading'>
                    Socket IO Service
                </div>
                <div className='line'>
                    This server handles all the game's live events, which get broadcasted on all the devices in that particular game.
                </div>
                <div className='heading'>
                    Hosting and Cloud
                </div>
                <div className='line'>
                    The frontend component is hosted in AWS S3 Bucket, and
                    the backend is hosted in Digital Ocean Server, with NGINX handling the proxy forwarding.
                </div>
                <br /> <br />
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[4])
                    }}
                >
                    <div className='heading'>
                        Demo time and when kids started playing ....
                    </div>
                </VisibilitySensor>
                <div className='line'>
                    After finishing up the design and the whole coding in
                    a week, I put this game in front of a group of kids 7-13 yrs of age.
                    Well, as you know, they enjoyed the game very much, and the key findings after the first demo game were as follows:
                </div>
                <br />
                <ColoredSection isReveal>
                    <ul className='line'>
                        <li> Everyone loved how easy it was to join the game with just a QR scan, <i> COOL FACTOR</i>!</li>
                        <li>Kids bashed my server by finding hidden bugs, and the good thing was, I worked on those bugs to make the app more robust</li>
                        <li>The soundfx and player's reward when getting a bingo was highly appreciated</li>
                        <li>I got constructive feedback, which was nice to have features and some significant changes which were needed right away</li>
                    </ul>
                </ColoredSection>
                <ImageSection url={Sana} caption={"Oh yes, I know how to join the game!"} />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ flexBasis: "50%" }}>
                        <ImageSection isHalf={true} url={SoloGame} caption={"Someone is enjoying it alone!"} />
                    </div>
                    <div style={{ flexBasis: "50%" }}>
                        <div className='line'>
                            The other thing I noticed was that kids would play this game alone.
                            They would not need any players. I found this very interesting and something to care about if a <strong>solo mode</strong> for this game is also possible.
                        </div>
                    </div>
                </div>
                <div className='heading'>
                    More testing
                </div>
                <div className='line'>
                    After the kids' experiment,
                    I made my colleagues play this game in the office through zoom. It was an instant hit.
                    Although the key findings were
                </div>
                <br />
                <ColoredSection isReveal>
                    <ul className='line'>
                        <li>More game logic bugs, nothing too serious that would break the game</li>
                        <li>Add auto-spin mode so that the host can leave it in auto mode and does not have to hit spin</li>
                        <li>Additional cosmetic and layout suggestions</li>
                    </ul>
                </ColoredSection>
                <br />
                <div className='line'>
                    <i> Since introduced to my office colleagues, I have been hosting this game to other teams as a team-building exercise.</i>
                </div>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[1])
                    }}
                >
                     <div className='heading'>
                    finally
                </div>
                </VisibilitySensor>
             
                <div className='line'>
                    I felt the BingoMingo experiment was a hit, and it can be a full-blown commercials game application. With my market research, I did not find any decent bingo game that the user can play in a family format.
                    Moreover, the solo mode can also be a good option.
                    I had fun putting in front of many crowds with different age groups, getting feedback, and managing to iterate quickly.
                </div>
                <ImageSection url={BingoMingo} caption={"BingoMingo! screenshots"} />
                <Ratings />
            </SafeContainer>

        </div>
    );
}

export default BingoMingoCode;