import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';


function Ratings() {

    const [page, setPage] = useState('');
    const [show, setShow] = useState(false)
    const [ratedNow, setRatedNow] = useState(false)

    useEffect(() => {
        const getLocation = window.location.pathname;
        const getSavedFlag = window.localStorage.getItem(getLocation + ' rating');
        if (!getSavedFlag) {
            setShow(true)
        } else {
            setShow(false)
        }
        setPage(getLocation);
    }, [])

    const handleRatingClicked = (action) => {
        window.localStorage.setItem(page + ' rating', action);
        setShow(false);
        setRatedNow(true);
        ReactGA.event({
            category: 'Ratings',
            action: 'clicked',
            value: action
          });
    }

    if (ratedNow) {
        return (
            <div className='Ratings fade-in'>
                <div className='rating-container'>
                    <span className='text'> Thank You!</span>
                </div>
            </div>
        )
    }

    if (!show) return <div />

    return (
        <div className='Ratings'>
            <div className='rating-container'>
                <span className='text'> RATE IT</span>
                <i className="far fa-thumbs-up icon" onClick={() => handleRatingClicked("Good")}></i>
                <i className="far fa-thumbs-down icon" onClick={() => handleRatingClicked("Bad")}></i>
            </div>
        </div>
    );
}

export default Ratings;