import React from 'react';
import ColoredSection from './ColoredSection';
import ReactGA from 'react-ga';

function IframeContainer({ url }) {

    const handleClicked = () => {
       
    }

    return (
            <div class='iframe-container'>
                <iframe
                    className="responsive-iframe"
                    src={url}
                    frameBorder="0"
                    onClick={() => handleClicked()}
                    >
                </iframe>
            </div>
    );
}

export default IframeContainer;