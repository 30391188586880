import React from 'react';
import ColoredSection from '../../components/ColoredSection';
import Pills from '../../components/Pills';
import SafeContainer from '../../components/SafeContainer';


function AboutPageWeb() {


    const skills = ["ReactJS", "React Native", "Javascript", "NodeJS", "AWS", "MongoDB", "JIRA", "AdobeXD",
        "FIGMA", "Tableau", "D3.js", "Nginx", "AngularJS", "UserTesing.com", "Miro", "SQL", "Github"]

    const ArvestAccomp = [
        `I Implemented and piloted AI-driven personalized financial insight feature in consumer mobile bank applications. As a team, I contributed to bringing different vendors together to make UX Journey decisions and implement the Arvest look and feel user interface for the AI service.`,
        `Contributed to setting up pilot metrics and tableau dashboards to measure the value of the feature.`,
        `I Designed (Adobe XD) and built (ReactJS) a debt simulator - personalized debt strategies to help customers manage debt.`,
        `The department saved thousands of dollars and countless hours as no external developing partner was needed to develop the MVP product.`,
        `With my adaptable skill contribution to the team, our team moves faster from a concept to an actual prototype that can be measured.`
    ]

    const ArvestResp = [
        `Using illustration, wireframe, and prototyping tools to validate concepts, drive design and experience decisions, and rapidly iterate through concepts.`,
        `Implementing design patterns to products and experiences using ReactJS.`,
        `Communicating effectively with diverse audiences, translating between business requests and technical needs, facilitates, and resolving disagreements over scope, design, and requirements.`,
        `Combining customer wants with enterprise needs to surface and resolve challenges to deliver optimal customer experiences.`,
        `Presenting and defending creative concepts and digital products to business partners, management, and senior executives.`
    ]

    const DrawAccomp = [
        `I helped revitalize the old customer end portal with ReactJS. The startup company started with developers, and in 2 years, the team grew to 12 people.`,
        `Our goal was to work in small batches and release them in the market. I successfully executed and led the frontend team to thrive success and business values.`
    ]

    const DrawResp = [
        `Worked in an Agile environment, with an ability to accommodate and test the newly proposed changes at any point of time during the release.`,
        `Used React.JS to create custom components for data manipulations and to display data in company standard format.`,
        `Involved HTTP calls for data updates in UI along with JSON data parsing.`,
        `Involved in planning and presenting User Interaction Models, Workflow, Wireframes, Screen Flows, and Prototypes.`,
        `The enhanced user experience by designing new web features using MVC Framework like React.JS.`
    ]

    const WebResp = [
        `Helped various local businesses to build their portfolio websites using HTML, Javascript, and CSS.`
    ]

    const UniResp = [
        `Responsible for identifying required resources to ensure completion of projects on time.`,
        `Handled the tasks of providing and managing voice-over pipeline, recording session schedule, casting, voice direction, and asset tracking.`,
        `Performed responsibilities of facilitating the communication channel between exterior and interior teams.`,
        `Handled the tasks of providing updates to the executive team on the progress of the project.`
    ]


    return (
        <div className='AboutPageWeb fade-in'>
            <SafeContainer>
                <div className='heading'>
                    About Me
                </div>
            
                <div className='body'>
                As a highly skilled software engineer with 6 years of experience working for both startup and enterprise companies, I am currently employed at JP Morgan Chase as a software engineer. In my previous role as a Senior UX Design Developer at Arvest Bank, I was responsible for the development and maintenance of user-centered web applications, as well as the design of intuitive interfaces implementing lean startup principles.

I have a strong track record of success in the industry, including helping to establish a software powerhouse for a startup company that grew from 4 employees to 50 in just 3 years. My expertise lies in technologies such as Python, API, Product Development Lifecycle, Lean Startup, FIGMA, AWS and ReactJS, and I am always seeking new opportunities to grow and challenge myself as a developer.

In addition to my professional pursuits, I am a passionate music producer and photographer, constantly seeking new creative outlets and ways to improve my craft. I am excited to continue growing my skills and making a positive impact in the world of software development.
                </div>

                <div className='section'>
                    <div className='heading'>
                        Skills
                    </div>
                    <div>
                    <ColoredSection>
                    <Pills list={skills} type={"inLine"} />
                </ColoredSection>
                    </div>
                </div>
                <div className='section'>
                    <div className='heading'>
                        Education
                    </div>
                    <div className='body'>
                        <div className='line'><strong>Bachelors of Audio Production</strong></div>
                        <div className='line'>SAE Institute, Sydney, NSW, Australia</div>
                        <div className='line'>December 2011</div>
                    </div>
                </div>
                <div className='section'>
                    <div className='heading'>
                        Certification
                    </div>
                    <div className='body'>
                        <div className='line'><strong>Backend Web Developer Professional Certificate</strong></div>
                        <div className='line'>University of Arkansas, Global Campus, Fayetteville, Arkansas</div>
                        <br />
                        <div className='line'><strong>Backend Web Developer Professional Certificate</strong></div>
                        <div className='line'>Mobile Developer Professional Certificate</div>
                    </div>
                </div>
                <div className='section'>
                    <div className='heading'>Experience</div>
                    <div className='sub-section'>
                        <div className='body'>
                            <div className='hero-primary'>
                                <div className='sub-header'>Senior Associate Software Engineer</div>
                                <div className='line'>JP Morgan & Chase Co, Atlanta, Georgia</div>
                                <div className='line'> <i>July 2022 - Present</i> </div>
                            </div>
                        </div>
                    </div>
                    <div className='sub-section'>
                        <div className='body'>
                            <div className='hero-primary'>
                                <div className='sub-header'>Senior UX Designer Developer (Digital Acceleration)</div>
                                <div className='line'>Arvest Bank, Bentonville, Arkansas</div>
                                <div className='line'> <i>December 2020 - Present</i> </div>
                            </div>
                            <div style={{ marginLeft: "1em" }}>
                                <div className='line'> <strong>Accomplishments:</strong> </div>
                                <ul>
                                    {ArvestAccomp.map((item, index) => {
                                        return (
                                            <li key={index} className='line'>{item}</li>
                                        )
                                    })}
                                </ul>
                                <div className='line'> <strong>Responsibilities:</strong> </div>
                                <ul>
                                    {ArvestResp.map((item, index) => {
                                        return (
                                            <li key={index} className='line'>{item}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='sub-section'>
                        <div className='body'>
                            <div className='hero-primary'>
                                <div className='sub-header'>Senior Associate - Software Development</div>
                                <div className='line'>Drawbridge Partners, Fayetteville, Arkansas</div>
                                <div className='line'> <i>January 2019 - December 2020</i> </div>
                            </div>
                            <div style={{ marginLeft: "1em" }}>
                                <div className='line'> <strong>Accomplishments:</strong> </div>
                                <ul>
                                    {DrawAccomp.map((item, index) => {
                                        return (
                                            <li key={index} className='line'>{item}</li>
                                        )
                                    })}
                                </ul>
                                <br />
                                <div className='line'> <strong>Responsibilities:</strong> </div>
                                <ul>
                                    {DrawResp.map((item, index) => {
                                        return (
                                            <li key={index} className='line'>{item}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='sub-section'>
                        <div className='body'>
                            <div className='hero-primary'>
                                <div className='sub-header'>Web Developer</div>
                                <div className='line'>Freelance (Part-time), Fayetteville, Arkansas</div>
                                <div className='line'> <i>January 2016 - December 2019</i> </div>
                            </div>
                            <div style={{ marginLeft: "1em" }}>
                                <div className='line'> <strong>Responsibilities:</strong> </div>
                                <ul>
                                    {WebResp.map((item, index) => {
                                        return (
                                            <li key={index} className='line'>{item}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='sub-section'>
                        <div className='body'>
                            <div className='hero-primary'>
                                <div className='sub-header'>Audio Producer</div>
                                <div className='line'>University of Arkansas, Fayetteville, Arkansas</div>
                                <div className='line'> <i>January 2015 - December 2019</i> </div>
                            </div>
                            <div style={{ marginLeft: "1em" }}>
                                <div className='line'> <strong>Responsibilities:</strong> </div>
                                <ul>
                                    {UniResp.map((item, index) => {
                                        return (
                                            <li key={index} className='line'>{item}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                </SafeContainer>
        </div>
    );
}

export default AboutPageWeb;