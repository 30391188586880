import React from 'react';

function ColoredSection({children,bg, isReveal }) {

    let classN = 'ColoredSection';
    if(isReveal) classN = 'ColoredSection reveal'

    return (
            <div className={classN} style={{backgroundColor: bg, transition: 'all 1s linear'}}>
            {children}
        </div>
    );
}

export default ColoredSection;