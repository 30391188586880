import React, { useState } from 'react';
import ColoredSection from '../../../../components/ColoredSection';
import IframeContainer from '../../../../components/IframeContainer';
import ImageSection from '../../../../components/ImageSection';
import Ratings from '../../../../components/Ratings';
import SafeContainer from '../../../../components/SafeContainer';
import ArvestGoImage from "./arvestgo.png"
import { bgColorCombo } from '../../../../styles/bgColorCombo';
import VisibilitySensor from 'react-visibility-sensor';

function ArvestGoProto() {
    const [bgTheme, setBgTheme] = useState(bgColorCombo[1])
    return (
        <div className='fade-in' style={{ backgroundColor: bgTheme.bg, color: bgTheme.color, transition: 'all 1s ease-in' }}>
            <SafeContainer>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(event ? bgColorCombo[1] : bgColorCombo[2])
                    }}
                >
                    <div className='title'>
                        Arvest Go Prototype and Wireframing Project
                    </div>

                </VisibilitySensor>
                <ImageSection isTop url={ArvestGoImage} caption={"Arvest Go Prototype/Wireframing Session"} />
                <div className='heading'>
                    Background
                </div>
                <div className='line'>
                    The Arvest Go app is a mobile banking application for personal account holders in the bank. There are more than 800,000
                    digital users in Arvest and many web applications for different lines of business. Among those applications, Arvest Go has the highest user traffic.
                    As the Arvest Go app has enormous traffic, most personal banking experience piloting and new feature tests are performed in this application.
                </div>
                <VisibilitySensor
                    onChange={(event) => {
                        setBgTheme(bgColorCombo[2])
                    }}
                >
                    <div className='heading'>
                        Problem
                    </div>

                </VisibilitySensor>
                <div className='line'>
                    In my first few weeks of employment at the Arvest bank,
                    I realized none of the bank departments had a working prototype of Arvest Go.
                    This slowed down new feature designs and third-party application integration experience.
                    As the application development team was a vendor, the vendor would charge big money to prototype an idea.
                </div>
                <div className='heading'>
                    Solution
                </div>
                <div className='line'>
                    I created the exact working prototype Arvest Go application in Adobe XD with my supervisor's support. With the sharable link to the prototype, many departments can leverage the power of Adobe XD and work with their team to ideate and solve feature issues in the application.
                    It provides a clear picture for any user who is using the application. Moreover,
                    it saves contractor hours payment by having the prototype built in-house.
                </div>
                <div className='heading'>
                    Prototype Playground
                </div>
                <div className='line'>
                    <i> Feel free to play around with the prototype </i>
                </div>
                <br />
                <IframeContainer url='https://xd.adobe.com/embed/b965649e-901a-49e5-80ae-9c76ebb9f3d6-ec36' />
                <Ratings />
            </SafeContainer>

        </div>
    );
}

export default ArvestGoProto;