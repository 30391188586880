import React from 'react';
import { useMediaQuery } from 'react-responsive'
import "./WorkPage.scss"
import WorkPageMobile from './WorkPageMobile';
import WorkPageWeb from './WorkPageWeb';

function WorkPage(props) {
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
    if (!isMobile) {
        return (
            <WorkPageWeb />
        );
    }
    return (
        <WorkPageMobile />
    )


}

export default WorkPage;