import React, { useEffect, useState } from 'react';
import WorkCard from '../../components/WorkCard';
import { workData } from '../../workData';
import ReactGA from 'react-ga';

function WorkPageWeb(props) {

    const [workItem, setWorkItem] = useState([]);
    const [navState, setNavState] = useState('design');

    useEffect(() => {
        const filerWorkItem = workData.filter(item => item.type === navState);
        setWorkItem([...filerWorkItem]);
    }, []);

    const handleSideNav = (nav) => {
        const filerWorkItem = workData.filter(item => item.type === nav);
        setWorkItem([...filerWorkItem]);
        setNavState(nav)
        ReactGA.event({
            category: 'Tab',
            action: 'clicked',
            value: "web  " + nav
        });
    }

    return (
        <div className='WorkPage fade-in'>
            <div className='body'>
                <div className='sideMenu'>
                    <div>
                        <div
                            className={navState === "design" ? 'navItem active' : 'navItem'}
                            onClick={() => handleSideNav('design')}>
                            DESIGN </div>
                        <div
                            className='navItem'
                            className={navState === "code" ? 'navItem active' : 'navItem'}
                            onClick={() => handleSideNav('code')}>
                            CODE  </div>
                    </div>
                </div>
                <div className='workSample'>
                    {workItem.map((item, index) => {
                        return <div className='card-wrapper' key={item.id}>
                            <WorkCard index={index} image={item.thumbnail} header={item.title} id={item.id} tags={item.tags} body={item.summary} />
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
}

export default WorkPageWeb;