import React from 'react';
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';

function WorkCard({ image, header, id, index, body, tags }) {
    const navigate = useNavigate();


    const handleNavigation = () => {
        navigate(`/workDetail/${id}`);
        ReactGA.event({
            category: 'Navigation',
            action: `MORE - CTA - ${id}`
        });
    }

    const renderTags = (tags) => {

        return tags.map((item, index) => {
            return (
                <div className='tag-item' key={index}> {item} </div>
            )
        })
    }

    return (
        <div className='WorkCard drop-down' style={{ animationDelay: `${index * .1}s` }} onClick={handleNavigation}>
            <div className='upperFrame'>
                <img src={`${process.env.PUBLIC_URL}/resources/Images/thumbnails/${image}`} alt="Image" />
            </div>
            <div className='lowerFrame'>
                <div className='header'>
                    {header}
                </div>
                <div className='cardBody'>
                    {body}
                </div>
                <div className='tags'>
                    {renderTags(tags)}
                </div>
                {/* <div className='moreButton' >
                    MORE  <i className="fas fa-arrow-right arrowIcon"></i>
                </div> */}
            </div>
        </div>
    );
}

export default WorkCard;