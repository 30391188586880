import React from 'react';

function SafeContainer({children}) {
    return (
        <div className='SafeContainer'>
           <div className='container'>
               {children}
           </div>
        </div>
    );
}

export default SafeContainer;