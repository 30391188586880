export const bgColorCombo = {
    1: {
        bg: 'rgb(249, 249, 249)',
        color: 'black',
        cardBg: '#ddf3eb'
    },
    2: {
        bg: 'black',
        color: 'white',
        cardBg: '#F4543D'
    },
    3: {
        bg: '#FBDADC',
        color: 'black',
        cardBg: '#FDED47'
    },
    4: {
        bg:"#2BFD86",
        color: "black",
        cardBg: 'white'
    }
}