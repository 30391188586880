import React from 'react';
import AboutPageWeb from './AboutPageWeb';
import { useMediaQuery } from 'react-responsive'
import AboutPageMobile from './AboutPageMobile';
import "./AboutPage.scss"


function AboutPage() {
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' })
    
    if (!isMobile) {
        return <AboutPageWeb />
    }
    return <AboutPageMobile />
}

export default AboutPage;